.idclient {
    /* position:relative; */

  }
   .idclientbody {
    display: flex;
    width:fit-content;
    flex-direction: column;
    align-items: center !important;
    vertical-align: center !important;
    text-align: center !important;
    justify-content: center !important;
      /* width:  100%; */
      height: auto;
      color: gray;
    }
    .fullscreen {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: black;
        z-index: 1000; /* Ensure it appears above other elements */
        display: flex;
        justify-content: center;
        align-items:center;
      }
      .fullscreen .qr-scanner {
        width: 100%;
        height: 100%;
        object-fit: cover; /* Ensure the QR scanner takes up the full space */
      } 


      .fullscreen video {
        width: 100%;
        height: 100%;
        object-fit: cover; /* Ensure the QR scanner takes up the full space */
      }

      .close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        color:white;
        z-index: 3000 ; /* Ensure it appears above other elements */
        background-color: red;
        border: none;
        padding: 10px 20px;
        cursor: pointer;
        font-size: 1.5em !important;
      }

      .camera-button {
        position: absolute;
        top: 10px;
        left: 10px;
        color:white;
        z-index: 3001; /* Ensure it appears above other elements */
        background-color: yellowgreen;
        border: none;
        padding: 10px 20px;
        cursor: pointer;
        font-size: 1.5em !important;
      }

      .selfie-button {

        width: 8em !important;  
        height: 8em !important ; 
        position:absolute;
        bottom: 15vh !important;
        border-radius: 50% !important ; 
        text-align: center;
        color: var(--custom-bg-color, #f8f9fa) !important;
        font-weight: bolder;
        font-size: 2em !important;
        background-color: var(--custom-color, yellowgreen) !important; 
        border-width: 0;
        margin: 5px;
        z-index: 4000;
      
      }


      .closeresult-button {
        width: 100% !important;
        background-color:var(--custom-color, yellowgreen) !important; 
        color:var(--custom-bg-color, #f8f9fa) !important;
        /* border-color: var(--custom-color, yellowgreen) !important;  */
        border:none;
        cursor: pointer !important;
        font-size: 1.25em !important;
    }



      .widget-loader-container {
        position: fixed; /* Ensure it covers the entire viewport */
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(255, 255, 255, 0.8); /* Optional: Add a semi-transparent background */
        z-index: 1000; /* Ensure it appears above other elements */
      }
      
      /* Loader styles */
      .widget-loader {
        border: 1px solid #f3f3f3; /* Light grey */
        border-top: 2px solid var(--custom-color, #999999) !important; 
        border-radius: 100%;
        width: 10em;
        height: 10em;
        animation: spin 1s linear infinite;
      }
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

    .admin-icon {
      font-size: 2em;
      color: yellowgreen;
      cursor: pointer;
      z-index: 7000;
    }


    .widget-input, .widget-button {
        padding: 10px 15px !important;
        font-size: 2em !important;
        border: 1px solid #ccc !important;
        border-radius: 4px !important;
        margin: 0.5em 0 !important;
    }
    
    .widget-input {
        width: 100%;
        max-width: 400px !important;
        box-sizing: border-box !important;  /* Include padding and border in the width */
    }
    
    .widget-button {
        width: 100px !important;
        height:100px!important;
        max-width: 200px !important;
        max-height: 200px !important;
        border-radius: 50%!important;
        background-color:var(--custom-color, yellowgreen) !important; 
        color:var(--custom-bg-color, #f8f9fa) !important;
        border-color: var(--custom-color, yellowgreen) !important; 
        cursor: pointer !important;
        font-size: smaller !important;
        font-weight: bolder !important;
    }
    .widget-button span {
      text-align: center !important;
    }
    /* .widget-button:hover {
        background-color: #D19C4D;
        border-color: #D19C4D;
    } */

    .dots-loader {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 10em;
      margin:1em;
    }

    .dot {
      width: 1em;
      height: 1em;
      background-color: gray;
      border-radius: 50%;
      animation: flash 2.5s infinite;
      transform: translateZ(0);
    }

    .dot:nth-child(1) {
      animation-delay: 0s;
    }

    .dot:nth-child(2) {
      animation-delay: 0.5s;
    }

    .dot:nth-child(3) {
      animation-delay: 1s;
    }


    @-webkit-keyframes flash {
      0%, 50%, 100% {
        background-color: gray;
      }
      25%, 75% {
        background-color: var(--custom-color, yellowgreen);
      }
    }


    @keyframes flash {
      0%, 50%, 100% {
        background-color: gray;
      }
      25%, 75% {
        background-color: var(--custom-color, yellowgreen);
      }
    }