.overlay {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column; /* Organize children vertically */
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.6); /* Black w/ opacity */
}











.street-input {
    width: 75vw; /* Adjust based on your layout */
    height: 25px; /* Or however tall you'd like it */
    font-size: 20px;
    text-align: center;
    border: 0.5px solid #ccc;
    border-radius: 5px;
    margin: 2px; /* Center the input if container is flex */
}
.street-input:focus {
    outline: none;
    color: var(--custom-color, yellowgreen) !important;  
    border-color:var(--custom-color, yellowgreen) !important;   /* Highlight color when focused */
    border-width: 0.5px;
}

.address-input {
    width: 25vw; /* Adjust based on your layout */
    height: 25px; /* Or however tall you'd like it */
    font-size: 20px;
    text-align: center;
    border: 0.5px solid #ccc;
    border-radius: 5px;
    margin: 2px; /* Center the input if container is flex */
}

.state-input {
    width: 20vw; /* Adjust based on your layout */
    height: 30px; /* Or however tall you'd like it */
    font-size: 20px;
    text-align: center;
    border: 0.5px solid #ccc;
    border-radius: 5px;
    margin: 2px; /* Center the input if container is flex */
    color:gray;
}

.address-input:focus {
    outline: none;
    color:var(--custom-color, yellowgreen) !important;  
    border-color:var(--custom-color, yellowgreen) !important;   /* Highlight color when focused */
    border-width: 0.5px;
}


.ssn-box:disabled {

    color: var(--custom-color, yellowgreen) !important;  
    font-weight: bold;
    background: transparent;
}

.address-container {
    position: relative;
    justify-content: center;
    text-align: center;
    align-items: center;
    display: flex; /* This will ensure your input boxes are in one line */
    margin: 1em;
    flex-direction: column;
    color:#ccc;
    z-index: 9999 !important;
  
    
}

/* input:focus + .inputs-container {
    position: fixed;
    top:50%; 
    left: 50%;
    transform: translate(-50%, 50%);
} */

.error-message {
    color: var(--custom-color, yellowgreen) !important;  
    margin-bottom: 10px; /* Add space below the error message */
}

.header-message {
    color: var(--custom-color, yellowgreen) !important;  
    margin-bottom: 10px; /* Add space below the error message */
}

/* button {
    padding: 10px 20px;
    font-size: 18px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    opacity: 0.9;
    cursor: pointer;
} */

.verify-button {
    padding: 10 10;
    width:100px !important;  
    height: 100px !important ; 
    max-width: 200px !important;
    max-height: 200 px !important;
    border-radius: 50% !important ; 
    text-align: center;
    color: var(--custom-bg-color, #f8f9fa) !important;
    font-weight: bolder;
    font-size: 1em;
    background-color: var(--custom-color, yellowgreen) !important;  
    border-width: 0;

  
  }

  .verify-button span {
text-align:    center;  
  }
  



button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
