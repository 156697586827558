.overlay {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column; /* Organize children vertically */
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.6); /* Black w/ opacity */
}

.digit-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px; /* Add space below the digit boxes */
    background-color: rgba(0,0,0,0.6) !important;
    padding: 2vh;
    z-index: 2000;
}









.digit-box {
    width: 40px;
    height: 40px;
    margin: 5px;
    font-size: 24px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
}
.digit-box:focus {
    outline: none;
    color:var(--custom-bg-color, #999999) !important; 
    border-color:var(--custom-color, #999999) !important; 
    border-width: 3px;
    z-index: 10000 !important;
}


.digit-box:disabled {

    color: var(--custom-bg-color, #999999) !important; 
    font-weight: bold;
    background: transparent;
}

.inputs-container {
    display: flex; /* This will ensure your input boxes are in one line */
    margin: 2em;
  
    
}

/* input:focus + .inputs-container {
    position: fixed;
    top:50%; 
    left: 50%;
    transform: translate(-50%, 50%);
} */

.error-message {
    color: #999999 !important; 
    margin-bottom: 10px; /* Add space below the error message */
}

.header-message {
    color: #999999 !important; 
    margin-bottom: 10px; /* Add space below the error message */
}

/* button {
    padding: 10px 20px;
    font-size: 18px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    opacity: 0.9;
    cursor: pointer;
} */

.verify-button {
    padding: 10 10;
    width: 5em !important;  
    height: 5em !important ; 
    border-radius: 50% !important ; 
    text-align: center;
    color: var(--custom-bg-color, #f8f9fa) !important;
    font-weight: bolder;
    font-size: smaller;
    background-color: var(--custom-color, #999999) !important; 
    border-width: 0;

  
  }
  


button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
