.timer-overlay {
    position: absolute;
    top: 0;
    left: 15%;
    width: 70% !important ;
    display: flex;
    align-items: center;
    padding: 5px;
    z-index: 5000;
  }
  
  .timer-circle {
    position:absolute;
    top:1vh;
    left: 0%;
    width: 15px; /* Adjust size as needed */
    height: 15px; /* Adjust size as needed */
    border-radius: 50%; /* Makes the div a circle */
    margin-right: 5px;
  }


  
  .timer-text {
    position:absolute;
    top:1vh;
    right: 0%;
    font-size: 18px;
    color: lightgray; /* Adjust color as needed */
    font-weight: bolder;
  }
  
  @media (max-width: 500px) {
  .timer-overlay {
    position: absolute;
    top: 0;
    left: 5%;
    width: 90% !important ;
    display: flex;
    align-items: center;
    /* padding: 5px; */
    z-index: 5000;
  }
}