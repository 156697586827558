/* html,
body {
  width: 100%;
  height: 100%;
  margin: 0 0;
  padding: 0 0;
} */

/* #root,
#viewerContainer {
  width: 100%;
  height: 100%;
} */

/* .image-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  box-sizing: border-box;
  align-items: center;
  flex-direction: column;
  padding: 10px 0px;
}

.image-container img {
  width: 80%;
  height: 40%;
  object-fit: contain;
  border: none;
}

.restore-button {
  display: flex;
  width: 80px;
  height: 40px;
  align-items: center;
  background: #fe8e14;
  justify-content: center;
  color: white;
  cursor: pointer;
  user-select: none;
} */

/* @media (orientation: landscape) {
  body {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
  }

  .landscape-warning {
      display: block;
      text-align: center;
  }
}

@media (orientation: portrait) {
  .landscape-warning {
      display: none;
  }
} */

.app-container {
  width: 100%;
  max-width: 375px !important;
  padding: 16px;
}

.app-container.desktop-view {
  width: 375px;
  max-width: 100%;
  margin: auto;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}