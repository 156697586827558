/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.dce-msg-poweredby{
  display:none;
}



div[slot="drawing-layer"] canvas  {
  height: 50vh !important;
  width:100vw !important;
  position: fixed !important;
  top:0 !important;
  left:0 !important;
  /* object-fit: cover !important; */
  }


.custombody{
  margin: 0;
  padding: 0;
  background-color: var(--custom-bg-color, #323232);
  color: var(--custom-color, gainsboro);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.webcam-container {
  margin-top: 0px;
    position: inherit;
  display: flex;
  width: 100%; 
  height:auto;
  align-items: center;
  text-align: center;
  justify-content: center;

}

.capture-container {
  margin-top: -10px;
    position: inherit;
  display: flex;
  width: 100%; 
  height:auto;
  align-items: center;
  text-align: center;
  justify-content: center;

}
.scan-container {
  margin-top: -5px;
    position: inherit;
  display: flex;
  width: 100%; 
  height:auto;
  align-items: center;
  text-align: center;
  justify-content: center;

}


.card-container {
  /* position: relative;
  display: flex;
  width: 100%; 
  height:auto;
  align-items: center;
  text-align: center;
  justify-content: center; */

}

.loader {
  position:absolute;
  top: 48vh;
  border: 1px solid #f3f3f3; /* Light grey */
  border-top: 2px solid ; /* Blue */
  border-top-color: var(--custom-color, #999999) !important; 
  border-radius: 100%;
  width: 4em;
  height: 4em;
  animation: spin 1s linear infinite;
}


.checkidloader {
  border: 1px solid #f3f3f3; /* Light grey */
  border-top: 2px solid ; /* Blue */
  border-top-color: var(--custom-color, #999999) !important; 
  border-radius: 100%;
  width: 4em;
  height: 4em;
  animation: spin 1s linear infinite;
}

.d-loader {
  position:absolute;
  top: 24vh;
  left:42%;
  border: 1px solid #f3f3f3; /* Light grey */
  border-top: 2px solid ; /* Blue */
  border-top-color: var(--custom-color, #999999) !important; 
  border-radius: 100%;
  width: 4em;
  height: 4em;
  animation: spin 1s linear infinite;
}


.s-loader {
  position:absolute;
  top: 60vh;
  left:42%;
  border: 1px solid #f3f3f3; /* Light grey */
  border-top: 2px solid ; /* Blue */
  border-top-color: var(--custom-color, #999999) !important; 
  border-radius: 100%;
  width: 4em;
  height: 4em;
  animation: spin 1s linear infinite;
}


.init-loader {

  border: 1px solid #f3f3f3; /* Light grey */
  border-top: 2px solid ; /* Blue */
  border-top-color: var(--custom-color, #999999) !important; 
  border-radius: 100%;
  width: 4em;
  height: 4em;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.cardbody {

  position: absolute;
  top: 52vh;
  width: 90vw;
  /* color: black; */
  font-weight: bolder;
  font-size: 1em;
   transition: all 0.1s; 
   /* display:none; */
}
/* .cardbody-focused {
  top:65vh !important;

} */

.challenge-container {
  position: relative;
  display: flex;
  width:80vw;
  height:50vh;
  align-items: center;
  justify-content: center;

}


.ssn-container {
  position:relative;
  display: flex;
  flex-direction: column;
  /* height:50vh; */
  /* top: 24px;
  left:0;
  min-width: 100vw;
  max-width:100vw; */
  /* transform: translate(-50%, -50%); */
  width:auto;
  align-items: center;
  justify-content: center;
  z-index: 4000;

}

.canvaswebcam-focused {
  display:none !important; /* Adjust as needed to position above the keyboard */

}

#idimage {
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  height: 50vh;
  margin: 0;
  object-fit: contain;
}


#barcodeimage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50vh;
  margin: 0;
  /* object-fit: cover; */
}




.webcam {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50vh;
  margin: 0;
  /* object-fit: cover; */
}

 .canvas {
  position: absolute;
  top: 0;
  left:0;
  width:100%;
  height:50vh;
  margin: 0;
  /* object-fit: cover; */
  z-index: 2000;
}


.webcamcanvas-focused {
  position: absolute;
  bottom:0%;
  left: 10%;
  max-width: 80%;
  height: auto;
  margin: 0;
}

/* p {
  margin: 10px !important;
} */

h5  {
  margin: 0 !important;
  padding: 0 !important;
}

/* h4 {
  color: yellowgreen !important;
} */



.capturebody {

  position: absolute;
  /* bottom: 2%; */
  left: 0vh !important ;
  top: 55vh;
  width: 100% !important;
  /* margin: 0 5%; */
  padding: 0;
  /* color: black; */
  font-size: 1em ;
  /* font-family: Arial, Helvetica, sans-serif; */
}

.peephole {

  position: absolute;
  top: 0;
  left:0;
  width:100vw;

}


.ddv-root {

  width: 80% !important ;
  left : 0 !important ;
  border:none !important;
}


.dce-scanarea  {
width: 100vh !important;
height :50vh;
left : 0 !important;
position:absolute !important;
top:0 !important;
/* background-color: green!important; */
}

.dce-video-container{
  width:100% !important;
  left:0 !important;
  /* height:100% !important; */
  /* height: 30vh !important ; */
  position:absolute;
  top:0 !important;
}

.dce-scanlight
{display:none !important;
  width:100% ;
  height:1% !important;
  left : 0%  ;
  position:absolute !important;
  animation:scanlightAnimation 3s linear infinite;;
  border-radius:0% !important;
  box-shadow:0px 0px 1vw 1px #999999 !important;
  background: var(--custom-color, #999999) !important; 
}

.barcodebody {

  position: absolute;
  width : 100vh;
 top: 70vh ;
transform: translate(-50%, -50%);
  /* color:black; */
  font-size: 1em !important ;
}


@media (max-width: 500px) {
  /* .startbutton{
    width: 100px !important;  
    height: 100px !important ; 
    font-size: smaller;
  } */
  .webcam {
    /* Adjust styles for smaller screens */
    left: 0%;
    object-fit: cover;
    /* max-width: 100%;
    height: auto;  */
  }
  .canvas {
    left:0%;
    width:100%;
    object-fit: cover;
  }

  .webcamcanvas-focused {
    top:30%;
    left: 0%;
    max-width: 100%;
    height: auto;
  }

  div[slot="drawing-layer"] canvas  {
    object-fit: cover !important;
    }

  .idCard{
    position: absolute;
    top: 3vh;
    width:90vw;
    left:5vw;

    z-index:2500;
    opacity:40%;
  }

  .face-line {
    position: absolute;
    top: 5vh !important;
    left: 50%;
    width: 50vw; /* Adjust the width proportionate to the webcam's width */
    height: 40vh !important;
    z-index: 30000 !important;
    transform: translate(-50%, -50%); /* Center the outline */
}



  

  .cardbody {

    top: 52vh ;
    font-size: 1.02em !important;

  }

  .cardbody-focused {
    top:52vh !important;
  
  }


  .ddv-root {

    width: 100% !important;
    left : 0 !important;
    border:none !important;
  }

  .dce-video-container {

    width: 100% !important;
    /* height:50vh !important; */
    left : 0 !important;

  }

  .dce-video-container dce-component div[slot="content"] video {
    /* Your desired styles here */
    object-fit: cover !important;
    /* z-index: 20000 !important; */
  }
  
  div-image-container {
    object-fit: cover !important;
  
  }



  .dce-scanarea {

    width :  100% !important;
    left : 0 !important;
    height: 50vh !important;
    object-fit: cover !important;

    
  }


  .dce-scanlight {

    width:100% !important;
    /* height:50vh !important; */
    left: 0 !important;
    object-fit: cover !important;
    display: block !important;
  }
  
  /* .capturebody {

    font-size: 1em !important;  
    top: 70vh;

  } */
  /* div[slot="drawing-layer"] canvas  {
    height: auto !important;
    } */
/*   
    .dce-video-container dce-component div[slot="content"] video {
      height: auto !important ; 
     
    } */

  .barcodebody {

  font-size: 1em !important;

  }
}

.checkmark-container {
  position: absolute;
  top: 125%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none; /* Initially hidden */
}
.image-checkmark-container {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none; /* Initially hidden */
}
.checkmark {
  /* Additional styling for the checkmark icon */
  font-size: 3em;
  color: #999999;

}

.undo {
  /* Additional styling for the checkmark icon */
  font-size: 3em;
  color: #999999;

}









.imagebody {

  position: absolute;
  left:0;
  /* bottom: 2%; */
  /* left: 50%; */
  width: 100vw;
 top: 52vh !important;
  /* transform: translate(-50%, -50%); */
  /* color:black; */
  font-size: 1em !important;
}




.startbutton {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100px;  /* Set a consistent width */
  height: 100px; /* Set a consistent height */
  max-width: 150px;  /* Set a consistent width */
  max-height: 150px; /* Set a consistent height */
  border-radius: 50%;
  text-align: center;
  color: var(--custom-bg-color, #f8f9fa) !important;
  font-weight: bolder;
  font-size: small;
  background-color: var(--custom-color, #999999) !important; 
  border-width: 0;
  margin: 5px;
  white-space: nowrap; /* Prevent text from wrapping */
  z-index: 15000 !important;
}

.startbutton span {
  text-align: center;
}



.quiz-input{
  padding: 10px 15px !important;
  font-size: 16px !important;
  border: 1px solid #ccc !important;
  border-radius: 4px !important;
  margin: 2px 0 !important;
  width: 100% !important;
  box-sizing: border-box !important; 
}



#results {
  border: 1px dashed grey;
  overflow: auto;
  width: 80vw;
  padding: 2vmin;
  margin-bottom: 3vh;
  height: 15vh;
}

.resultText {
  color: #cE5E04
}

.bigger {
  font-size: large;
  margin-bottom: 2%;
}

.barcode-container {
  position: relative;
  width: 100%; /* Full width */
  align-items: center;
  text-align: center;
  justify-content: center;

}

#UIElement {
  vertical-align: top !important;
  font-size: medium;
  width: 100% !important ;
  display: flex;
  height: 50vh;

}

/* #viewerContainer{
  vertical-align: top !important;

} */

.ddv-core .ddv-audio-container, .ddv-core .ddv-camera-container, .ddv-core .ddv-camera-container canvas, .ddv-core .ddv-camera-container video, .ddv-core .ddv-canvas-container, .ddv-core .ddv-main-and-thumbnail .ddv-main-container .ddv-scroller-and-canvas, .ddv-core .ddv-scroll-container, .ddv-core .ddv-video-container{
  box-sizing: border-box !important;
  left:0 !important ;
  top:0 !important;
  position: absolute !important;
  width: 100% !important;
  height: 50vh !important;
  /* height: auto !important; */
  z-index:2000!important;

}
.ddv-camera-container{
  background-color: #323232 !important;

}




#UIElement img {
  /* display: flex;
  flex-direction: column;
  align-items: flex-start; */
  vertical-align: top !important;
  width:100%;
  height:100% !important;
  max-width: 100%;
  max-height: 100%;
  /* border: solid 1px green; */
}
.landscape-warning {
  display: none;
}

.mic-icon {
  font-size: 30px !important; /* Adjust size as needed */
  color: #999999 ;
}

.mic-icon.speaking {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
      opacity: 0.5;
  }
}

@keyframes scanlightAnimation {
  0%, 100% {
      top: 0; /* Start and end at the top of the scan area */
  }
  50% {
      top: 100%; /* Move to the bottom of the scan area */
  }
}


.session-terminated{
  display: flex !important;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: large;
  opacity: .75;
  z-index: 2000;
  padding-left: 10px !important;
}
.timer-image {
  height: 1em;
  justify-content: center;
   position:absolute;
  bottom:1vh;
  right: 1vw;
}

.start-over {
  height: 1em;
  justify-content: center;
   position:absolute;
  bottom:1vh;
  color: var(--custom-color,#999999) !important;
  left: 1vw;
}

/* unvisited link */
a:link {
  color:  var(--custom-color,#999999) !important;
}

/* visited link */
a:visited {
  color:  var(--custom-color,#999999) !important;
}

/* mouse over link */
a:hover {
  color:  var(--custom-color,#999999) !important;
}

/* selected link */
a:active {
  color: var(--custom-color,#999999) !important;
}




.logo {
  height: 2em;
  margin: .5em;

}

.session-starting{
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align:center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--custom-bg-color,#3d3c3d) !important;
  opacity: .97;
  color: var(--custom-color, #999999) !important; 
  font-size: x-large;
  z-index: 3000;
}


@media (orientation: landscape) and (min-aspect-ratio: 13/9){
  .landscape-warning {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align:center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color:var(--custom-bg-color, #3d3c3d) !important;
    opacity: .95;
    color: var(--custom-color, #999999) !important;  
    font-size: 1em;
    z-index: 5000;
  }

  

}

.dce-video-container dce-component div[slot="content"] video {
  /* Your desired styles here */
   position: fixed !important;
  left: 0 !important;
  top: 0 !important; 
  width: 100vw !important;
  height: 50vh !important ; /* Adjust this as needed */
  /* object-fit: cover !important; */
  /* z-index: 20000 !important; */
}

div-image-container {
  position: fixed !important;
  left: 0 !important;
  top: 0 !important; 
  width: 100vw !important;
  height: 50vh !important ; /* Adjust this as needed */
  /* object-fit: cover !important; */

}

/* #div-ui-container {width:100%;height:100%;position:absolute;left:0;top:0;} */